import React from 'react';
import styles from './Footer.module.scss';

const Footer = () => (
  <div className={styles.Footer}>
    <div className={styles.FooterContent}> Built 💻 using ReactJs with ❤️</div>
  </div>
);

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
