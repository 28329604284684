const videos = [
    {
      title: "Build Cupertino Flutter Chat App with Firebase in 4 HOURS!",
      keywords: "firebase,flutter firebase,flutter tutorial,flutter tutorial for beginners,firebase tutorial,flutter app development,flutter app,firebase authentication,flutter chat app with firebase,flutter firebase auth,chat app flutter,flutter chat app,flutter phone authentication,flutter firebase auth phone number,flutter firebase cloud functions,Flutter Firebase Storage,flutter firebase tutorial,flutter firebase chat app,firebase flutter,flutter,whatsapp clone,dart,node,nodejs,docker,keynotecast",
      id: "tnnUBEpELy8",
    },
    {
      title: "Flutter UI iOS App Calculator with Mobx | Speed Code",
      keywords: "Building a Calculator App in Flutter,how to create calculator in flutter,flutter tutorial in hindi,flutter state management,mobx react,mobx tutorial,flutter calculator app,flutter calculator tutorial,flutter simple calculator,simple calculator in flutter,calculator flutter app,mobx,immutable,install flutter,flutter app development,Flutter Calculator App,flutter app,flutter for beginners,keynotecast",
      id: "fUlPqDV6qO8",
    },
    {
      title: "Tips & Tricks Dart Flutter - Master interviews coding challenges",
      keywords: "dart tutorial,array methods,coding in dart,dart programming,dart programming tutorial,dart lang,dart map,dart tutorial 2022,flutter dart oop,dart programming language,iterators in dart,dart programming for beginners,Code this not that,Coding life,Coding is fun,Coding tutorial,Flutter Firebase tutorial",
      id: "fGcaIp7ppUg",
    },
    {
      title: "Why Flutter Firebase Stack?",
      keywords: "flutter 2022,flutter firebase,firebase tutorial,flutter tutorial,google developers,flutter firebase app,firebase flutter,firebase tutorial flutter,firebase tutorial 2022",
      id: "K2GwAq0i3MM",
    },
    {
      title: "Upload Picture Flutter Firebase Storage",
      keywords: "flutter firebase storage upload,Firebase Firestore Flutter,cloud firestore tutorial,firebase cloud storage flutter,firebase flutter database,flutter chat app with firebase,flutter cloud firestore,flutter firestore,flutter firestore query,flutter firestore tutorial,flutter tutorial,flutter upload image,image upload firebase storage,flutter file upload,flutter firebase upload,firebase file upload tutorial,flutter firebase storage,Flutter,keynotecast",
      id: "RJvc0LEWmkI",
    },
    {
      title: "Dart Pro tips and tricks",
      keywords: "dart tutorial,array methods,coding in dart,dart programming,dart programming tutorial,dart lang,dart map,dart tutorial 2022,flutter dart oop,dart programming language,iterators in dart,dart programming for beginners,Code this not that,Coding life,Coding is fun,Coding tutorial,Flutter Firebase tutorial",
      id: "9WayLZxfJu0",
    },
    {
      title: "Google Sign In Firebase FlutterFireUI Android & iOS",
      keywords: "google sign in firebase flutter,flutter firebase google sign in ios,flutter web firebase google sign in,google sign in firebasegoogle sign in firebase flutter,flutter google sign in firebase android,flutter google sign in firebase ios,flutterfire ui google sign in,google sign in firebase,flutter google sign in firebase,flutter google login firebase,firebase google sign in flutter,google sign in firebase android,keynotecast,firebase flutter authentication",
      id: "IeR8TKHokFY",
    },
    {
      title: "Easy Flutter Firebase Timestamp into DateTime",
      keywords: "timestamp flutter firebase,flutter firebase timestamp,flutter firebase,firebase firestore,flutter tutorial 2022,flutter firebase tutorial 2022,flutter datetime,firebase fieldvalue,flutter widgets,flutter firebase listview builder,flutter firebase database listview,flutter firebase listview,flutter firebase tutorial",
      id: "IhAxU8Dk4wM",
    },
    {
      title: "You May Regret Not Trying This!",
      keywords: "flutter cupertino,cupertino flutter package,cupertino flutter widgets,cupertino widgets flutter,flutter cupertino widgets,flutter cupertino search bar,flutter cupertino_icons,flutter cupertinoapp,flutter cupertino datepicker,flutter cupertino and material,flutter cupertino picker,flutter cupertino dialog,flutter cupertino bottom navigation bar,flutter cupertino button,flutter cupertino bottom sheet,flutter cupertino textfield,flutter cupertino app vs materialapp",
      id: "ZCTIC4gJcJ8",
    },
    {
      title: "Flutter Firebase Infinite List",
      keywords: "flutter tutorial,flutter firebase crud,flutter tutorial for beginners,firebase tutorial,flutter firebase,crud flutter firestore,flutter tutorial in hindi,firebase database,keynotecast,flutter chat app,build chat app flutter,chat app flutter firebase,chat app using flutter and firebase,flutter chat app the right way,flutter chat app tutorial,flutter chat app with firebase,flutter firebase chat app tutorial,flutter chat application with firebase",
      id: "EiRxyCn08pw",
    },
    {
      title: "Easy Flutter Firebase Cloud Firestore CRUD",
      keywords: "flutter firebase,flutter firebase auth phone number,flutter firebase phone number authentication,flutter chat app with firebase,firebase authentication,flutter firebase auth,flutter login ui,firebase authentication flutter,chat app flutter firebase,flutter phone auth,flutter phone authentication firebase,flutter phone authentication,flutter phone auth ui,flutter phone auth ios,phone authentication firebase,firebase phone authentication flutter,flutter chat app",
      id: "bmY6iHZ8hss",
    },
    {
      title: "Simple Flutter Firebase AuthGate Firebase Auth",
      keywords: "flutter firebase,flutter firebase auth phone number,flutter firebase phone number authentication,flutter chat app with firebase,firebase authentication,flutter firebase auth,flutter login ui,firebase authentication flutter,chat app flutter firebase,flutter phone auth,flutter phone authentication firebase,flutter phone authentication,flutter phone auth ui,flutter phone auth ios,phone authentication firebase,firebase phone authentication flutter,flutter chat app",
      id: "rukEIwV2U3k",
    },
    {
      title: "🚀 ⚡️Trailer: Day in the life of a Software Engineer WFH Lisbon",
      keywords: "day in the life of a software engineer work from home,remote work from home jobs no experience 2022,remote work from home jobs 2022,remote work 2022,day in the life of a software engineer,day in the life,software engineer,daily standup meeting,day in the life of a software developer,software developer,software developer day in the life,software engineer day in the life,day in the life software engineer office,tech company office,become a software engineer,developers",
      id: "Wx0MD8V6NGo",
    },
    {
      title: "Simple FlutterFire UI Phone Number Authentication",
      keywords: "flutter firebase unnable to receive code,firebase authentication,flutter phone auth github,firebase tutorial,flutter firebase auth,flutter firebase authentication tutorial,flutter firebase auth tutorial,flutter firebase authentication,firebase authentication flutter,firebase authentication in flutter,flutter firebase tutorial,flutter phone auth,mobile phone verification,mobile phone verification code does not send,flutter phone auth ui,Flutter phone authentication",
      id: "W-dYWkcqFXc",
    },
    {
      title: "Quick Search Bar Flutter Firebase MobX - Flutter Chat App Firebase #9",
      keywords: "Firebase auth,Flutter firebase otp authentication,build chat app flutter,chat app flutter,chat app flutter firebase,chat app using flutter and firebase,flutter chat app,flutter chat app the right way,flutter chat app tutorial,flutter chat app ui,flutter chat app with firebase,flutter chat application with firebase,flutter chat ui,flutter firebase,flutter firebase authentication tutorial,flutter firebase chat app tutorial,flutter firebase tutorial",
      id: "WKjQNpGcdww",
    },
    {
      title: "JavaScript If Else Statement #7",
      keywords: "if else shorthand statement javascript,if statement logic javscript,nested if statement javascript,if else javascript,javascript tutorial for beginners full course,javascript tutorial for beginners visual studio code,javascript tutorial advanced,javascript for beginners 2022,web development,cleverprogrammer,programming,developer,javascript,coding,programmer,software developer,software development,react js,tutorial",
      id: "ITmoxYjtZZ0",
    },
    {
      title: "JavaScript Comparison Operators #6",
      keywords: "javascript comparison operators,javascript type conversions,javascript variables,type conversion in javascript,javascript type coersion,javascript typeof,javascript type coercion,type conversions,web development,programming tutorial javascript,javascript tutorials,learn javascript for beginners 2022,es6 javascript,js tutorial,js tutorial advanced,operators in javascript,javascript lessons,javascript comparison operators tutorial",
      id: "_CyDNN9VaB0",
    },
    {
      title: "JavaScript Operators #5",
      keywords: "javascript type conversions,javascript variables,type conversion in javascript,javascript type coersion,javascript typeof,javascript type coercion,type conversions,web development,programming tutorial javascript,javascript tutorials,learn javascript for beginners 2022,es6 javascript,js tutorial,javascript tutorial for beginners 2022,javascript tutorial for beginners full course,js tutorial for beginners bangla,js tutorial advanced,operators in javascript",
      id: "CQV5Y3BbcWQ",
    },
    {
      title: "JavaScript Type Coercion #4",
      keywords: "javascript type conversions,javascript data types,javascript variables,type conversion in javascript,javascript type coersion,javascript typeof,javascript type coercion,type conversions,web development,programming tutorial javascript,learn javascript for beginners 2022,javascript tutorial for beginners 2022,javascript tutorial for beginners full course,js tutorial for beginners bangla,js tutorial advanced,javascript type conversion,javascript for beginners 2022",
      id: "0yKFDXB3gNw",
    },
    {
      title: "JavaScript Data Types #3",
      keywords: "javascript data types,javascript variables,data types in javascript,learn javascript,modern javascript,vs code-javascript,javascript es6,es6 tutorial,web development,programming tutorial javascript,javascript tutorials,learn javascript for beginners 2022,js tutorial,javascript tutorial for beginners 2022,javascript tutorial for beginners full course,js tutorial for beginners bangla,js tutorial advanced,es6 tutorial 2022,js variables,data types",
      id: "OqkG4VB9HJM",
    },
    {
      title: "JavaScript Variables #2",
      keywords: "web development,programming tutorial javascript,javascript tutorials,learn javascript for beginners 2022,es6 javascript,js tutorial,javascript tutorial for beginners 2022,javascript tutorial for beginners full course,javascript tutorial for beginners visual studio code,java scripting tutorial for beginners 2022,java scripting tutorial for beginners full course,java script tutorial,js tutorial for beginners bangla,js tutorial advanced,es6 tutorial 2022",
      id: "BXZiIEnZ2_0",
    },
    {
      title: "Setup & Introduction #1",
      keywords: "web development,programming tutorial javascript,javascript tutorials,learn javascript for beginners 2022,es6 javascript,js tutorial,javascript tutorial for beginners 2022,javascript tutorial for beginners full course,javascript tutorial for beginners visual studio code,java scripting tutorial for beginners 2022,java scripting tutorial for beginners full course,java script tutorial,js tutorial for beginners bangla,js tutorial advanced,es6 tutorial 2022",
      id: "QrVSSv59FXE",
    },
    {
      title: "Upload Profile Picture with Firebase Storage with MobX #8",
      keywords: "firebase cloud storage flutter,image upload firebase storage,flutter upload image,flutter 2 firebase,Firebase Firestore Flutter,flutter tutorial,flutter firestore,flutter firestore tutorial,flutter app development,flutter chat app,flutter tutorial for beginners,cloud firestore tutorial,cloud firestore flutter,firebase flutter database,flutter 2 firestore,flutter 2.2 firestore,flutter cloud firestore,flutter chat app with firebase,flutter chat",
      id: "lbVMzPj-PxU",
    },
    {
        title: "Easy Chat Messages Screen Firebase Firestore with MobX #7",
        keywords: "flutter 2 firebase,Firebase Firestore Flutter,firestore,flutter firebase,flutter firebase tutorial,flutter tutorial,flutter firestore,flutter firestore tutorial,flutter app development,flutter chat app,flutter tutorial for beginners,cloud firestore tutorial,firebase tutorial,cloud firestore flutter,flutter firestore query,firebase flutter database,flutter 2 firestore,flutter 2.2 firestore,flutter cloud firestore,flutter chat app with firebase,flutter chat",
        id: "jKgFwEjeNq0",
      },
     {
        title: "Software Testing Explained - Tech Talks #1",
        keywords: "software testing,automation testing,software engineering,bdd testing,cucumber testing,cucumber framework,quality assurance best practices,testing methodologies,quality assurance,qa best practises,qa methodology,software testing process,software testing for beginners,what is software testing,quality assurance engineer,manual testing,black box testing,software tester,qa engineer,software development life cycle,software testing mentor,phases of testing",
        id: "Dr9PGkVFUrg",
      },
     {
        title: "Easy Chat Messages Using Firebase Firestore #6",
        keywords: "flutter 2 firebase,Firebase Firestore Flutter,firestore,flutter firebase,flutter firebase tutorial,flutter tutorial,flutter firestore,flutter firestore tutorial,flutter app development,flutter chat app,flutter tutorial for beginners,cloud firestore tutorial,firebase tutorial,cloud firestore flutter,flutter firestore query,firebase flutter database,flutter 2 firestore,flutter 2.2 firestore,flutter cloud firestore,flutter chat app with firebase,flutter chat",
        id: "aKYfq-VPnMA",
      },
     {
        title: "User Record Cloud Firebase Firestore #5",
        keywords:
          "flutter 2 firebase,Firebase Firestore Flutter,firestore,flutter firebase,flutter firebase tutorial,flutter tutorial,flutter firestore,flutter firestore tutorial,flutter app development,flutter chat app,flutter tutorial for beginners,cloud firestore tutorial,firebase tutorial,cloud firestore flutter,flutter firestore query,firebase flutter database,flutter 2 firestore,flutter 2.2 firestore,flutter cloud firestore,flutter chat app with firebase,flutter chat",
        id: "uWFsCKx-YBM",
      },
     {
        title: "Easy Flutter Firebase Phone Number Authentication #4",
        keywords:
          "flutter firebase phone number authentication,Flutter Firebase Mobile Phone Verification UI,phone authentication firebase flutter,flutter phone authentication,flutter phone auth ui,flutter phone auth ios,phone authentication firebase,flutter phone auth,flutter phone auth firebase,flutter phone authentication firebase,flutter firebase auth phone number,mobile phone verification,firebase phone authentication flutter,flutter chat app with firebase,flutter firebase",
        id: "ua9AfnGvP3Q",
      },
      {
        title: "Flutter Phone Number Authentication UI #3",
        keywords:
          "Flutter Firebase Mobile Phone Verification UI,phone authentication firebase flutter,flutter phone authentication,flutter phone auth ui,flutter phone auth ios,firebase authentication,phone authentication firebase,flutter phone auth,flutter firebase tutorial,flutter app tutorial,flutter tutorial for beginners 2021,flutter firebase auth,flutter phone auth firebase,flutter auth ui,flutter phone authentication firebase,flutter firebase auth phone number",
        id: "hjeMe6LSG-c",
      },
      {
        title: "Flutter Firebase Firestore #2",
        keywords:
          "flutter 2 firebase,Firebase Firestore Flutter,firestore,flutter firebase,flutter firebase tutorial,flutter tutorial,flutter firestore,flutter firestore tutorial,flutter app development,flutter chat app,flutter tutorial for beginners,cloud firestore tutorial,firebase tutorial,cloud firestore flutter,flutter firestore query,firebase flutter database,flutter 2 firestore,flutter 2.2 firestore,flutter cloud firestore,flutter chat app with firebase",
        id: "zRRCY9mJNNE",
      },
      {
        title: "Flutter Tab Bar #1",
        keywords:
          "chat app flutter,Flutter Chat App,Flutter Cupertino Bottom Navigation Bar,Flutter Bottom Navigation Bar,bottom navigation flutter,flutter beginner tutorial,flutter bottom navigation bar example,flutter bottom navigation bar tutorial,flutter bottom navigation bar ui,flutter navigation bar,flutter tutorial 2021,flutter tutorial for beginners,chat app flutter firebase,build chat app flutter,fluttertabbar,tabbarview,flutter tabbedAppBar,flutter navigation bottom bar",
        id: "leax33lxTgQ",
      },
      {
        title: "Permission Set Groups in Salesforce #4",
        keywords:
          "Permission set groups trailhead,app builder,get hands-on with permission set groups,get hands-on with premission set groups,lightning app builder,mute permissions in permission set groups trailhead,permission set group,permission set groups,permission set groups create a permission set group,permission set groups salesforce,permission sets,premission set groups,premission set groups create a premission set group,salesforce app builder,salesforce tutorial",
        id: "jtBC_Hf2F9g",
      },
      {
        title: "Flutter Crash Course 2021 - Calculator App in Flutter",
        keywords:
          "Flutter Crash Course,flutter tutorial for beginners,Building a Calculator App in Flutter,flutter calculator tutorial,flutter calculator app,calculator flutter app,simple calculator in flutter,flutter for beginners,flutter app development,complete flutter tutorial,flutter state management,flutter course full tutorial for beginners,flutter 2021 tutorial,flutter app from scratch,best flutter tutorial 2021,flutter crash course,flutter crash course 2021",
        id: "GoAAb-2-agA",
      },
      {
        title: "Field Level Security in Salesforce #3",
        keywords:
          "field level security,tutorials,salesforce,salesforce tutorial,salesforce demo,salesforce crm,data security,trailhead,salesforce developer,salesfoerce app builder,salesforce data security,faizal patel,free tutorials for salesforce,salesforce data security control access to objects,salesforce data security control access to fields,fields level security,salesforce fields level security,data security in salesforce,Control access to fields trailhead",
        id: "vY3-MEsROtw",
      },
      {
        title: "Control Access to Objects Salesforce #2",
        keywords:
          "salesforce,salesforce tutorial,salesforce demo,salesforce crm,data security,trailhead,salesforce developer,salesfoerce app builder,salesforce data security,faizal patel,free tutorials for salesforce,salesforce data security control access to objects",
        id: "bwuHbyGEu4Y",
      },
      {
        title: "Control Access to the Org in Salesforce #1",
        keywords:
          "salesforce,salesforce tutorial,salesforce demo,salesforce crm,data security,trailhead,salesforce developer,salesfoerce app builder,salesforce data security,salesforce data security control access to org,salesforce control access to org,salesforce add and deactivate users,salesforce add users,salesforce password policies,salesforce thrusted ip,salesforce login hours user profile,salesforce ip address user profile,faizal patel,free tutorials for salesforce",
        id: "dhzYiXAZ6UE",
      },
      {
        title: "Replace LG G3 Logic Board",
        keywords:
          "how to fix the logic board lg g3,LG G3 Motherboard how to change the logic board lgg3,how to change the motherboard lgg3,changing the logic board lgg3,lgg3 logic board removal,lgg3 logic board replacement,replacing the logic board lgg3,how to fix the logic board lgg3,fixing the logic board lgg3,lgg3 logic board repair,lgg3 disassembly,lgg3 teardown,lgg3 repair,how to change the logic board lg g3,fixing the logic board lg g3,replacing the logic board lg g3,lg g3",
        id: "o-OCiJREz2E",
      },
      {
        title: "Setup Salesforce DX with Visual Studio Code",
        keywords:
          "salesforce dx visual studio code,salesforce dx,sfdx,viscose for salesforce,sfdx cli,salesforce,salesforce development,salesforce extension pack,salesforce development tools,salesforce cli,sfdxcli,visual studio code,visual studio code extensions,visual studio code tutorial,development tools - salesforce dx - sdks,salesforce developers tv,salesforce training,salesforce tutorial,salesforce demo,faizal patel,aprender salersforce,salesforce ci cd",
        id: "3ATxUl4cCOo",
      },
      {
        title:
          "Create Calculator in Flutter - Flutter Calculator App",
        keywords:
          "keynotecast,building a calculator app in flutter,how to create calculator in flutter,flutter calculator tutorial,flutter tutorial for beginners,flutter calculator,flutter calculator app,calculator flutter app,simple calculator in flutter,flutter ui",
        id: "2rZjEhfnDKg",
      },
      {
        title: "Unboxing XFX Radeon RX 580 8GB Graphic Card",
        keywords:
          "keynotecast,Unboxing XFX Graphic,XFX Graphic,PC Hardware,Gaminh Hardware,XFX,GPU,XFX Radeon,xfx radeon rx 580 8gb,xfx radeon rx 580 gts black edition,xfx radeon,xfx radeon rx 580 8gb fortnite,directx 12,xfx radeon rx 580 8gb flight sim 2020",
        id: "4CRyQpVCYjs",
      },
      {
        title: "Unboxing Aerocool Atomic ARGB Mini Tower PC Gaming Case",
        keywords:
          "keynotecast,AeroCool Atomic PC Case,AeroCool Atomic,AeroCool Atomic Case,AeroCool Atomic Gaming PC Case,Budget Gaming PC Case,Computer Hardware,ARGB motherboard,compact mini tower case",
        id: "-V4dvtXSFgw",
      },
      {
        title: "How to give and receive feedback on Base Camp Toastmasters",
        keywords:
          "keynotecast, toastmasters,evaluation and feedback toastmasters speech,toastmasters feedback basecamp,how to give feedback on basecamp,how to give feedback on toastmasters basecamp,how to give and receive feedback on toastmasters basecamp,toastmasters basecamp",
        id: "m-W-qvSeBhk",
      },
      {
        title: "How to livestream to instagram from OBS",
        keywords:
          "keynotecast,How to stream your computer to INSTAGRAM with OBS Studio,How to stream to Instagram with OBS,How to Stream to Instagram using OBS,How to Stream to Instagram using OBS Studio,OBS Studio Instagram,Stream OBS to Instagram,How to stream desktop to instagram,how to stream with obs on instagram,how to live stream on instagram with obs,how to stream on instagram with obs mac,how to livestream on instagram with obs studio,Use OBS and Yellow Duck",
        id: "sp8xrKQGdT0",
      },
      {
        title: "How to add music to instagram story",
        keywords:
          "keynotecast,instagram stories,instagram story,tutorial instagram stories,how to add music to instagram story,how to add music to instagram story iphone,how to add music to instagram story picture,how to add song to instagram story,add music to instagram stories,add song to instagram story iphone,instagram,how to add music to instagram story latest,insert music to instagram story,create instagram story with music,create instagram stories with song",
        id: "ILESRl7cHkQ",
      },
      {
        title: "How to Create and Launch Polls in Zoom Meetings",
        keywords:
          "how to create zoom polls,how to launch a zoom poll,launch zoom poll in meeting,how to make a zoom poll,launch poll in zoom,how to poll in zoom,zoom polls,zoom polling tutorial,zoom polling tutorials,how to poll in zoom meetings,zoom pole questions,zoom polling feature,zoom polling,how to quiz students in zoom,zoom poll questions,zoom for teachers,how to quiz students on zoom,how to create polls on zoom meeting,zoom polling in meeting",
        id: "falgd5ATufU",
      },
      {
        title:
          "How to fix obs lag on low end pc",
        keywords:
          "keynotecast,obs streaming settings,best obs settings for streaming,how to setup obs for streaming,best obs streaming settings 2020,best obs settings,obs studio stream settings,obs settings for streaming,obs stream settings,best streamlabs obs settings,best obs settings for streaming 2020,best obs settings for streaming fortnite,best obs streaming settings for valorant,best obs streaming settings 2020 no lag,best obs streaming settings for lowend pc,2020 obs guide",
        id: "XLWXEW24vV4",
      },
      {
        title: "What skills do you need to be a software engineer?",
        keywords:
          "software engineering,software engineer skills,programming languages,become a software engineer,software developer,become a software developer,learn software development,software engineering skills,most important skill for coding,software development skills,learn programming channel,best skill to be a programmer,software engineer,day in the life of a software engineer,Software development tips in 2020,What skills do you need to be a software engineer?",
        id: "hiGBR-DMXZ0",
      },
      {
        title: "How to acquire a new free pathway in 2020 - Toastmasters",
        keywords:
          "keynotecast,public speaking,toastmasters,toastmasters pathways,pathways,toastmastersbuypathways,toastmastersinternational",
        id: "LonO9ucQ-VY",
      },
      {
        title: "Java vs JavaScript | Difference between Java and JavaScript",
        keywords:
          "keynotecast,difference between java and javascript,java vs javascript syntax,java versus javascript,what's the difference between java and javascript,javascript vs java,javascript versus java,javascript and java,java and javascript,java vs javascript difference,java vs javascript diferencias,java and javascript difference,java et javascript difference,java vs javascript which is better,java vs javascript,java vs javascript web development",
        id: "sBVJuM1uAmg",
      },
      {
        title: "How To Make Yourself More Visible Online [10 ultimate tips]",
        keywords:
          "keynotecast,get more visible online,how to increase your online visibility,how to increase online visibility,brand yourself,how to brand yourself as an artist,self branding,market yourself on social media,how to promote yourself,how to market yourself,networking fundamentals,how to reach out to clients,how to gain leads,reach out to your target audience,how to reach out to prospective clients,how to generate leads",
        id: "28ozPzD6Des",
      },
      {
        title:
          "How To Make Cool Instagram Stories right from your Mobile Phone with Canvas App in 2020",
        keywords:
          "keynotecast,how to make instagram stories,instagram stories,design cool instagram stories,tutorial instagram stories,instagram story,instagram stories canvas,insta story with canvas app,how to make cool instagram stories with mobile phone,how to make instagram stories using canva,how to build instagram stories with canvas app,canvas app to create instagram stories",
        id: "MXVZ3kEoB7s",
      },
      {
        title: "Salesforce beginner tutorial",
        keywords:
          "get started with salesforce crm,salesforce lightning,salesforce standard objects,salesforce sales cloud,salesforce introduction,what is crm?,salesforce crm,customer relationship management,introduction to salesforce,salesforce starters,salesforce ultimate beginners,starting with CRM,salesforce training,salesforce training videos for beginners,salesforce training videos,salesforce tutorial,salesforce crm tutorial for beginners,salesforce beginner tutorial",
        id: "8NbzBNv3YBQ",
      },
      {
        title:
          "How to deploy webapp using Firebase Hosting and Github Actions | CI/CD | GitHub Actions + Firebase",
        keywords:
          "firebase hosting,firebase,GitHub,GitHub actions,firebase cli,deploy webapps using firebase hosting,using firebase hosting with github actions,multiple environments using firebase hosting and github actions,get started with firebase hosting,firebase deploy,firebase and github,github and firebase,firebase hosting tutorial,firebase tutorial,How to deploy webapp using Firebase Hosting",
        id: "tb_9Yf3xFlE",
      },
];
    
export default videos;