import React, { useState } from 'react';
import styles from './SinglePage.module.scss';
import AppMainBar from '../AppMainBar/AppMainBar';
import Footer from '../Footer/Footer'
import PageScaffold from '../PageScaffold/PageScaffold'

const SinglePage = () => {
  const [searchText, setSearchText] = useState('');
  
  return (
    <div className={styles.SinglePage}>

      <AppMainBar onSearch={(event) => { console.log('callback: ', event.target.value); setSearchText(event.target.value); }} />
      <PageScaffold searchText={searchText} />
      <Footer />
    </div>);

};
SinglePage.propTypes = {};

SinglePage.defaultProps = {};

export default SinglePage;
