import './App.scss';

//import {connectFirestoreEmulator } from 'firebase/firestore';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
/* import { db, auth, store, func } from './config';
import { connectAuthEmulator } from 'firebase/auth';
import { connectStorageEmulator } from 'firebase/storage';
import { connectFunctionsEmulator } from 'firebase/functions'; */
import SinglePage from './components/SinglePage/SinglePage';
import { useEffect } from 'react';


// eslint-disable-next-line no-restricted-globals
/* if (location.hostname === 'locahost') { 
  connectFirestoreEmulator(db, 'localhost', 8080);
  connectAuthEmulator(auth, 'localhost:8040');
  connectStorageEmulator(store, 'localhost', 8081);
  connectFunctionsEmulator(func,'localhost', 8082);
} */
/*
var col = collection(db, 'testabc');
addDoc(col, { name: 'test' });
*/

const App = () => {

  useEffect(() => {
    if(/donate/.test(window.location.href)){
      window.location.replace('https://www.buymeacoffee.com/keynotecast');
    }
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SinglePage/>} />
      </Routes>
    </BrowserRouter >
  );
};

export default App;
