import React from "react";
import styles from "./PageScaffold.module.scss";
import {
  Typography,
  Card,
  CardContent
} from "@mui/material";
import videos from "./data";

const url = (id) => {
  return "https://youtu.be/" + id;
};
const imageUrl = (id) => {
  return "https://i.ytimg.com/vi/" + id + "/maxresdefault.jpg";
};

const PageScaffold = (props) => {
  console.log('PageSc', props.searchText);
  const elements = videos.filter((filter) => { return filter.keywords.includes(props.searchText) || filter.title.includes(props.searchText)}).map((el) => {
    return (
      <div className={styles.Card} key={el.id}>
        {" "}
        <Card sx={{ minWidth: 275 }}>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {el.title }
            </Typography>
            <a href={url(el.id) } target="_blank" rel="noreferrer"><img src={imageUrl(el.id)} alt="" height="200"/></a>
          </CardContent>
        </Card>{" "}
      </div>
    );
  });

  return <div className={styles.PageScaffold}>{elements}</div>;
};

PageScaffold.propTypes = {};

PageScaffold.defaultProps = {};

export default PageScaffold;
